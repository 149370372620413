import moment from 'moment';

export const DATE_FORMAT = 'MMM DD, YYYY';
export const DATETIME_FORMAT = 'LLL';
export const MONTH_AND_DAY_FORMAT = 'MMM D';
export const MONTH_AND_YEAR_FORMAT = 'MMMM YYYY';
export const MONTH_AND_YEAR_DASH_FORMAT = 'MM-YY';
export const DAYPICKER_ARIA_LABEL_FORMAT = 'ddd MMM DD YYYY';
export const DATE_INPUT_FORMAT = 'll';
export const DATE_DASH_FORMAT = 'YYYY-MM-DD';
export const DATE_DASH_FORMAT_II = 'MM-DD-YYYY';
export const WHOLE_DAY_FORMAT = 'dddd';
export const DATE_INPUT_ALLOWED_FORMATS = [
  DATE_INPUT_FORMAT,
  DATE_DASH_FORMAT,
  moment.ISO_8601,
  DATE_DASH_FORMAT_II,
  'MM/DD/YYYY',
  'YYYY/MM/DD',
];

export const TIME_TO_LAUCH_DATE_FORMAT = `h:mma [on] ${DATE_FORMAT}`;

export default DATE_FORMAT;
