// Sorting
export const TIME_ENTRIES_SORT_ACTIVITY_DATE_EARLIEST = 'reference_date';
export const TIME_ENTRIES_SORT_ACTIVITY_DATE_LATEST = '-reference_date';
export const TIME_ENTRIES_SORT_TEAM_MEMBER_ASC = 'user__first_name';
export const TIME_ENTRIES_SORT_TEAM_MEMBER_DESC = '-user__first_name';
export const TIME_ENTRIES_SORT_DEPARTMENT_ASC = 'user__department__title';
export const TIME_ENTRIES_SORT_DEPARTMENT_DESC = '-user__department__title';
export const TIME_ENTRIES_SORT_TIME_TRACKED_LOWEST = 'inputted_duration';
export const TIME_ENTRIES_SORT_TIME_TRACKED_HIGHEST = '-inputted_duration';
export const TIME_ENTRIES_SORT_CREATED_EARLIEST = 'created';
export const TIME_ENTRIES_SORT_CREATED_LATEST = '-created';

export const TIME_ENTRIES_SORT_TITLES_FORMATTED = {
  [TIME_ENTRIES_SORT_ACTIVITY_DATE_EARLIEST]:
    'Activity Date (Earliest First)',
  [TIME_ENTRIES_SORT_ACTIVITY_DATE_LATEST]:
    'Activity Date (Latest First)',
  [TIME_ENTRIES_SORT_TEAM_MEMBER_ASC]: 'Team Member (A-Z)',
  [TIME_ENTRIES_SORT_TEAM_MEMBER_DESC]: 'Team Member (Z-A)',
  [TIME_ENTRIES_SORT_DEPARTMENT_ASC]: 'Department (A-Z)',
  [TIME_ENTRIES_SORT_DEPARTMENT_DESC]: 'Department (Z-A)',
  [TIME_ENTRIES_SORT_TIME_TRACKED_LOWEST]:
    'Time Tracked (Lowest First)',
  [TIME_ENTRIES_SORT_TIME_TRACKED_HIGHEST]:
    'Time Tracked (Highest First)',
  [TIME_ENTRIES_SORT_CREATED_EARLIEST]: 'Created (Earliest First)',
  [TIME_ENTRIES_SORT_CREATED_LATEST]: 'Created (Latest First)',
};

export const TIME_ENTRIES_DEFAULT_SORT = TIME_ENTRIES_SORT_ACTIVITY_DATE_LATEST;
export const TIME_ENTRIES_TABLE_PAGE_SIZE = 20;
export const TIME_ENTRIES_SORT_KEY = 'order_by';
export const TIME_ENTRIES_PRESET_KEY = 'preset';
export const TAG_NAME_CONTAINS = 'name';

// Filtering
export const TIME_ENTRIES_FILTER_BILLABLE = 'billable';
export const TIME_ENTRIES_FILTER_CLIENT_COMPANY = 'client';
export const TIME_ENTRIES_FILTER_CREATED = 'created';
export const TIME_ENTRIES_FILTER_CREATED_LTE = 'created_lte';
export const TIME_ENTRIES_FILTER_CREATED_GTE = 'created_gte';
export const TIME_ENTRIES_FILTER_PROJECT = 'project';
export const TIME_ENTRIES_FILTER_PROJECT_STATUS = 'project_status';
export const TIME_ENTRIES_FILTER_REFERENCE_DATE = 'reference_date';
export const TIME_ENTRIES_FILTER_REFERENCE_DATE_LTE = 'reference_date_lte';
export const TIME_ENTRIES_FILTER_REFERENCE_DATE_GTE = 'reference_date_gte';
export const TIME_ENTRIES_FILTER_TAG = 'tags';
export const TIME_ENTRIES_FILTER_TAG_NULL = 'tags_null';
export const TIME_ENTRIES_FILTER_USER = 'user';
export const TIME_ENTRIES_FILTER_USER_DEPARTMENT = 'user__department';
export const TIME_ENTRIES_FILTER_TITLE_CONTAINS = 'title_contains';

export const NO_TAGS_FILTER_ID = -1;

export const TIME_ENTRIES_FILTERS_VALUES = [
  TIME_ENTRIES_FILTER_BILLABLE,
  TIME_ENTRIES_FILTER_CLIENT_COMPANY,
  TIME_ENTRIES_FILTER_CREATED,
  TIME_ENTRIES_FILTER_CREATED_LTE,
  TIME_ENTRIES_FILTER_CREATED_GTE,
  TIME_ENTRIES_FILTER_PROJECT,
  TIME_ENTRIES_FILTER_PROJECT_STATUS,
  TIME_ENTRIES_FILTER_REFERENCE_DATE,
  TIME_ENTRIES_FILTER_REFERENCE_DATE_LTE,
  TIME_ENTRIES_FILTER_REFERENCE_DATE_GTE,
  TIME_ENTRIES_FILTER_TAG,
  TIME_ENTRIES_FILTER_TAG_NULL,
  TIME_ENTRIES_FILTER_USER,
  TIME_ENTRIES_FILTER_USER_DEPARTMENT,
];

export const TIME_ENTRIES_DATE_BOUNDARIES = ['is', 'before', 'between', 'after'];
export const TIME_ENTRIES_ACTIVITY_DATE_OPTION_MAP = {
  is: TIME_ENTRIES_FILTER_REFERENCE_DATE,
  before: TIME_ENTRIES_FILTER_REFERENCE_DATE_LTE,
  between: TIME_ENTRIES_FILTER_REFERENCE_DATE_LTE,
  after: TIME_ENTRIES_FILTER_REFERENCE_DATE_GTE,
};
export const TIME_ENTRIES_CREATED_DATE_OPTION_MAP = {
  is: TIME_ENTRIES_FILTER_CREATED,
  before: TIME_ENTRIES_FILTER_CREATED_LTE,
  between: TIME_ENTRIES_FILTER_CREATED_LTE,
  after: TIME_ENTRIES_FILTER_CREATED_GTE,
};

// for use in displaying the filters as chips
export const TIME_ENTRIES_FILTERS_FORMATTED = {
  [TIME_ENTRIES_FILTER_CLIENT_COMPANY]: 'Client Company',
  [TIME_ENTRIES_FILTER_CREATED]: 'Created',
  [TIME_ENTRIES_FILTER_PROJECT]: 'Project',
  [TIME_ENTRIES_FILTER_PROJECT_STATUS]: 'Project Status',
  [TIME_ENTRIES_FILTER_REFERENCE_DATE]: 'Activity Date',
  [TIME_ENTRIES_FILTER_TAG]: 'Tag',
  [TIME_ENTRIES_FILTER_TAG_NULL]: 'No Tag',
  [TIME_ENTRIES_FILTER_USER]: 'Team Member',
  [TIME_ENTRIES_FILTER_USER_DEPARTMENT]: 'Department',
};

export const TIME_ENTRIES_FILTER_CHIPS = {
  [TIME_ENTRIES_FILTER_BILLABLE]: 'Billable',
  [TIME_ENTRIES_FILTER_CLIENT_COMPANY]: 'ClientCompany',
  [TIME_ENTRIES_FILTER_CREATED]: 'Created',
  [TIME_ENTRIES_FILTER_CREATED_GTE]: 'Created',
  [TIME_ENTRIES_FILTER_CREATED_LTE]: 'Created',
  [TIME_ENTRIES_FILTER_PROJECT]: 'Project',
  [TIME_ENTRIES_FILTER_PROJECT_STATUS]: 'ProjectStatus',
  [TIME_ENTRIES_FILTER_REFERENCE_DATE]: 'ReferenceDate',
  [TIME_ENTRIES_FILTER_REFERENCE_DATE_GTE]: 'ReferenceDate',
  [TIME_ENTRIES_FILTER_REFERENCE_DATE_LTE]: 'ReferenceDate',
  [TIME_ENTRIES_FILTER_TAG]: 'TimeEntryTag',
  [TIME_ENTRIES_FILTER_TAG_NULL]: 'TimeEntryTagNull',
  [TIME_ENTRIES_FILTER_USER]: 'TeamMember',
  [TIME_ENTRIES_FILTER_USER_DEPARTMENT]: 'UserDepartment',
};

export const TIME_ENTRIES_MAX_DURATION = 2147347234; // max duration in backend
export const TIME_ENTRIES_NO_TASK_KEY = 'no-task';
export const TIME_ENTRIES_TEMP_ID_PREFIX = 'temp-';
